<template>
    <div class="relative w-24 text-center truncate group">
        <svg
            class="absolute top-0 right-0 w-4 h-4 text-red-500 opacity-0 cursor-pointer group-hover:opacity-60"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            @click="remove"
        ><path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
        /></svg>
        <div
            class="w-12 h-12 mx-auto bg-center bg-cover rounded opacity-75"
            :style="{ backgroundImage: 'url(' + sourceThumbnail + ')' }"
        />
        <div class="mt-1 text-xs text-gray-500">
            {{ file.name }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        file: {
            type: [Object, File],
            required: true
        }
    },

    emits: ['remove'],

    data () {
        return {
            source: null
        }
    },

    computed: {
        sourceThumbnail () {
            if (this.source?.includes('data:image/png') || this.source?.includes('data:image/jpeg')) {
                return this.source
            }

            if (this.source?.includes('application/pdf')) {
                return '/img/icons/001-pdf.png'
            }

            if (this.source?.includes('zip')) {
                return '/img/icons/003-zip.png'
            }

            if (this.source?.includes('word')) {
                return '/img/icons/005-word.png'
            }

            if (this.source?.includes('spreadsheet')) {
                return '/img/icons/004-xls.png'
            }

            return this.source
        }
    },

    created () {
        this.readFile()
    },

    methods: {
        readFile () {
            const reader = new FileReader()

            reader.onload = (e) => {
                this.source = reader.result
            }

            reader.readAsDataURL(this.file)
        },

        remove () {
            this.$emit('remove')
        }
    }
}
</script>
